import React, { useState } from "react";
import styled from "styled-components";
import Hero from "./Hero";
import Footer from "./Footer";

const Home = () => {
	return (
		<Main>
			<Content>
				<InnerCont>
					<Hero />
					<Footer />
				</InnerCont>
			</Content>
		</Main>
	);
};

export default Home;

const Main = styled.div``;

const Content = styled.div`
	position: relative;
	z-index: 100;
	height: 100%;
	width: 100%;
`;

const InnerCont = styled.div``;

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

:root {
  --custom-highlight-color: #eaded2; 
  /* sand */
  --accent-100: #D6CCBA;
  /* orange */
  --accent-200: #bb3e03;
  /* ash */
  --accent-300: #1A1A1A;
  /* grey */
  --accent-400: #403d39;
  /* cream */
  --accent-500: #F0E8DE ;
  /* dark green */
  --bg-200: #223021;

  
}

::selection {
  background-color: var(--custom-highlight-color);
  color: #000814; 
 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  width: 100vw;
}


  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }


`;

export default GlobalStyles;

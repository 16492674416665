import React from "react";
import styled from "styled-components";
import { IoMoonOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const About = () => {
	const navigate = useNavigate();

	return (
		<Div>
			<div>
				<h1>ABOUT ME</h1>
			</div>
			{/* <CloseButtonContainer>
				<Button onClick={() => navigate("/")}>
					<IoMoonOutline />
				</Button>
			</CloseButtonContainer> */}
		</Div>
	);
};

export default About;

const Div = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	position: absolute;
	top: 0;
	margin: 0 2rem;
	box-sizing: border-box;
	border-radius: 0.3rem;
	background-color: transparent;
	color: white;
`;

const CloseButtonContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	margin: 1rem 2rem;
`;

const Button = styled.button`
	background-color: transparent;
	border: none;
	font-size: 1.4rem;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: var(--accent-100);
	}
`;

import React, { useState } from "react";
import styled from "styled-components";
import About from "./About";
import Projects from "./portfolio/Projects";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
	const [selected, setSelected] = useState("home");
	const navigate = useNavigate();

	const handleSwitch = () => {
		switch (selected) {
			case "about":
				return <About setSelected={setSelected} />;
			case "work":
				return <Projects setSelected={setSelected} />;
			case "contact":
				return <Contact setSelected={setSelected} />;
			default:
				break;
		}
	};

	const handleNavigation = (path) => {
		navigate(path);
		toggleSidebar();
	};

	return (
		<Main isOpen={isOpen}>
			<CloseButton>
				<button onClick={toggleSidebar}>|||</button>
			</CloseButton>
			<Content>
				<InnerCont>
					<ButtonContainer>
						<Buttons
							onClick={() => {
								handleNavigation("/");
							}}
						>
							<Span>01</Span>
							home
						</Buttons>
					</ButtonContainer>
					<ButtonContainer>
						<Buttons
							onClick={() => {
								handleNavigation("/about");
							}}
						>
							<Span>02</Span>
							about
						</Buttons>
					</ButtonContainer>
					<ButtonContainer>
						<Buttons
							onClick={() => {
								handleNavigation("/work");
							}}
						>
							<Span>03</Span>
							work
						</Buttons>
					</ButtonContainer>
					<ButtonContainer>
						<Buttons
							onClick={() => {
								handleNavigation("/services");
							}}
						>
							<Span>04</Span>
							services
						</Buttons>
					</ButtonContainer>
					<ButtonContainer>
						<Buttons
							onClick={() => {
								handleNavigation("/contact");
							}}
						>
							<Span>05</Span>
							contact
						</Buttons>
					</ButtonContainer>
				</InnerCont>
				{handleSwitch()}
			</Content>
		</Main>
	);
};

export default Sidebar;

const Main = styled.div`
	position: absolute;
	top: 0;
	display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	box-sizing: border-box;
	animation: myAnim 0.7s ease 0s 1 normal forwards;
	z-index: 1000;

	@keyframes myAnim {
		0% {
			opacity: 0;
			transform: translateY(-10px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const CloseButton = styled.div`
	font-size: 1.5rem;
	position: absolute;
	top: 3rem;
	right: 1rem;
	z-index: 1000;
	cursor: pointer;

	button {
		background-color: transparent;
		color: var(--accent-200);
		border: none;
	}
`;

const Content = styled.div`
	position: relative;
	z-index: 100;
	height: 100%;
	width: 100%;
`;

const InnerCont = styled.div`
	margin: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 0.2rem;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	box-sizing: border-box;
	z-index: 100;
	background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(15px);
	border-radius: 0.2rem;

`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 22rem;
	/* transition: text-decoration 0.3s ease-in-out; */

	&:hover {
		/* text-decoration: var(--accent-200) line-through; */
	}
`;

const Buttons = styled.button`
	background-color: transparent;
	color: var(--accent-400);
	/* color: #403d39; */
	margin: 1rem;
	border: none;
	padding: 0.5rem;
	font-size: 3rem;
	font-family: "Cabinet Grotesk", sans-serif;
	text-align: left;
	border-radius: 0.3rem;
	transition: text-decoration 0.3s ease-in-out;

	&:hover {
		color: var(--accent-100);
		text-decoration: var(--accent-200) line-through;
	}
`;

const Span = styled.span`
	font-size: 1.3rem;
	color: var(--accent-200);
	margin-right: 4rem;
	font-style: italic;
	font-family: "Gambarino", sans-serif;
	/* display: none; */

	&:hover {
		text-decoration: none;
		/* display: flex; */
	}
`;

import React from "react";
import styled from "styled-components";
import Clock from "react-live-clock";

const Hero = () => {
	return (
		<Div className="hero">
			<ClockContainer>
				<Clock format={"HH:mm:ss"} ticking={true} timezone={"US/Eastern"} />
			</ClockContainer>
			<HeaderContainer>
				<Header>Iqra Imran Syed</Header>
			</HeaderContainer>
			<SubHeader>
				<p>Based in Montreal</p>
				<p>Frontend Developer</p>
			</SubHeader>
		</Div>
	);
};

export default Hero;

const Div = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	box-sizing: border-box;
`;

const Header = styled.h1`
	color: var(--accent-100);
	font-size: 10vw;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border-top: solid grey 1px;
	border-bottom: solid grey 1px;
	width: calc(100vw - 6rem);
`;

const SubHeader = styled.div`
	color: var(--accent-200);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 1rem;
	gap: calc(100vw - 30rem);

	p {
		font-size: 1.2rem;
		/* font-size: 2vw; */
	}
`;

const ClockContainer = styled.div`
	color: var(--accent-400);
	border: solid var(--accent-400) 1px;
	margin: 1rem;
	padding: 0.4rem 1rem;
	border-radius: 2rem;
	position: absolute;
	top: 0;
	left: 0;
`;
